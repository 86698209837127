<template>
  <b-container class="chat-box">
    <b-row>
      <b-col cols="12" lg="10" class="mx-auto">
        <div
          v-for="(msg, msgidx) in messages"
          :key="msgidx"
          class="chat-record mt-2"
        >
          <ChatMessageComponent
            :initMsg="msg"
            :key="msgidx"
            :msgidx="msgidx"
            :ref="`msgRef-${msg.name}`"
            :context="context"
            @select-card="handleCardClick"
            @increase="handleIncrease"
            @decrease="handleDecrease"
            @continue="continueChat"
            @scroll-to-bottom="scrollToBottom"
            @scroll-to-msg="scrollToMsg"
            @select-checkbox="handleCheckbox"
            @set-text-input="setTextInput"
            @selector-change="handleSelectorChange"
          />

          <div>
            <b-row
              v-if="msg.isFinal"
              class="w-100 reset-button py-2 m-1"
              @click="resetChat"
            >
              <b-col>Reiniciar chat</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <!-- <b-col col lg="2" class="d-none d-sm-block">
          <b-card
            bg-variant="light"
            title="Costo:"
            style="position: sticky; top: 4em"
          >
            <b-card-text>
              <b>{{ cost }}</b>
              <b-img :src="appleIcon" width="22px" class="pl-1 mb-2 mx-0" />
            </b-card-text>
          </b-card>
        </b-col> -->
    </b-row>
  </b-container>
</template>
<script>
import informationService from "@/services/informationService";
import customTestService from "@/services/customTestService";
import customMaterialService from "@/services/customMaterialService";
import chatBotService from "@/services/chatBotService";
import ChatMessageComponent from "./ChatMessageComponent.vue";
import CreateMaterial from "@/components/botpie-assistant/CreateMaterial";
import ApplicationComponent from "@/components/botpie-assistant/ApplicationComponent";
import PremiumViewComponent from "@/components/botpie-assistant/PremiumViewComponent";
import assistedMaterialService from "@/services/assistedMaterialService";
import TextMessageComponent from "./TextMessageComponent.vue";
// import materialDocumentSocket from "@/mixins/materialDocumentSocket";

export default {
  components: {
    ChatMessageComponent,
    CreateMaterial,
  },
  name: "ChatBotMaterials",
  // mixins: [materialDocumentSocket],
  data() {
    return {
      currentQuestionIndex: "action",
      context: {},
      answers: {},
      selectedCard: null,
      messages: [],
      prices: {},
      cost: 0,
      appleIcon: require("@/assets/img/icono-manzana.svg"),
      starIcon: require("@/assets/img/icono-estrella.svg"),
      copyIcon: require("@/assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_link.svg"),
      backIcon: require("@/assets/img/eureka_icons/eureka_svg_icons/Íconos_atrás.svg"),
      reviewIcon: require("@/assets/img/eureka_icons/eureka_svg_icons/Ajustados sin fondo_revisar.svg"),

      types: ["text", "button", "quickReplies"],
      questions: {
        action: {
          name: "action",
          text: "¡Hola! Soy tu asistente virtual. ¿En qué puedo ayudarte hoy?",
          type: "options",
          single_choice: true,
          nextQuestionIndex(context) {
            console.log("context", context);
            return "grade";
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                resolve(this.answers);
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // this.currentQuestionIndex = this.nextQuestionIndex;
          },
          answers: [
            {
              name: "Crear material",
              value: 1,
              isSelected: false,
              nextQuestionIndex: "grade",
            },
            {
              name: "Aplicar material",
              value: 1,
              isSelected: false,
              nextQuestionIndex: "application",
            },
          ],
        },
        grade: {
          name: "grade",
          text: `!Hola ${this.$store.state.login.first_name}! Hagamos una un material interactivo. <br> Dime ¿Con qué curso quieres comenzar?`,
          hint: "Escoge solo un curso",
          type: "options",

          answersLoaded: false,
          single_choice: true,
          nextQuestionIndex: "subject",
          answers: [],
          process_dic: {
            "Pre-kinder": "PK",
            Kinder: "K",
            "1° básico": "1°",
            "2° básico": "2°",
            "3° básico": "3°",
            "4° básico": "4°",
            "5° básico": "5°",
            "6° básico": "6°",
            "7° básico": "7°",
            "8° básico": "8°",
            "1° medio": "1°M",
            "2° medio": "2°M",
            "3° medio": "3°M",
            "4° medio": "4°M",
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve, reject) => {
                informationService
                  .getPieGrades()
                  .then((data) => {
                    data["grades"]
                      .filter((g) => ![14, 15, 16].includes(g[0]))
                      .forEach((ans) => {
                        this.answers.push({
                          original_name: ans[1],
                          name: this.process_dic[ans[1]],
                          code: ans[0],
                          isSelected: false,
                          class: "col-sm-2 col-3 pr-1 pl-0 pb-1",
                          bootstrap_classes: "font-weight-bold",
                          nextQuestionIndex: "subject",
                        });
                      });
                    this.answersLoaded = false;
                    resolve(this.answers);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        subject: {
          name: "subject",
          text: "¡Muy bien! Ahora dime ¿Con qué asignatura?",
          hint: "Escoge solo una asignatura",
          type: "options",
          answersLoaded: false,
          nextQuestionIndex: "oa",
          answers: [],
          single_choice: true,
          process_dic: {
            "Lenguaje y comunicación / Lengua y Literatura": "Lenguaje",
            Matemáticas: "Matemáticas",
            // "Modelo ecológico funcional": "Modelo ecológico funcional",
            // Música: "Música"
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve, reject) => {
                informationService
                  .getCardSubjectsValues()
                  .then((data) => {
                    data
                      .filter((s) => [7, 29].includes(s[0]))
                      .forEach((ans) => {
                        this.answers.push({
                          name: this.process_dic[ans[1]],
                          code: ans[0],
                          isSelected: false,
                          class: "col-5 pr-1 pl-0 pb-1",
                          nextQuestionIndex: "oa",
                        });
                      });
                    resolve(this.answers);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        oa: {
          name: "oa",
          text: () => {
            const grade =
              this.$store.state.chatbot.conversation.grade.answer.original_name;
            const subject =
              this.$store.state.chatbot.conversation.subject.answer.name;
            return `Estos son los objetivos de aprendizaje de ${grade} para ${subject}<br> ¿Cuales te gustaría trabajar?`;
          },
          hint: () => {
            const starIcon = this.starIcon;
            return `Escoge tus objetivos de aprendizaje. <br> Los <img src='${starIcon}' width='22px' class='pl-1 mb-2'/></img> son basales`;
          },
          // type: "options",
          optionalCheckbox: true,
          // define de nuevo type, si optionalCheckbox es true, entonces type es options, si no, es options-counter
          type: "hybrid-options",
          optionalCheckboxText: "Activar Indicadores de Evaluación",
          answersLoaded: false,
          answers: [],
          nextQuestionIndex(context) {
            return context["oa"].optionalCheckbox
              ? "indicadores"
              : context["subject"]["name"] === "Lenguaje"
              ? "text_topic"
              : "material";
          },
          single_choice: false,
          getAnswers(context) {
            if (!this.answersLoaded) {
              return new Promise((resolve, reject) => {
                chatBotService
                  .getPrice({ code: "OA" })
                  .then((prices) => {
                    customTestService
                      .getHashtagsByContent(
                        context.subject.code,
                        context.grade.code
                      )
                      .then((data) => {
                        customTestService.getTagsFiltered(data[1]).then((d) => {
                          d.sort((a, b) => a.priority - b.priority).forEach(
                            (ans) => {
                              let short_name = ans.name.split(" ").slice(-1);
                              this.answers.push({
                                name:
                                  "<strong style='font-size: 1.2rem;'>" +
                                  short_name +
                                  "</strong> - " +
                                  ans.summary,
                                description: ans.description,
                                isSelected: false,
                                price: prices[0].price,
                                class: "col-sm-12 col-12",
                                subtags: ans.subtags,
                                count: 0,
                                bootstrap_classes: "text-left px-2",
                                priority: ans.priority,
                                id: ans.id,
                              });
                            }
                          );
                          this.answersLoaded = true;
                          resolve(this.answers);
                        });
                      });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },

          routine() {
            console.log("routine");
            console.log(this.answers);
            // this.currentQuestionIndex = this.nextQuestionIndex;
            this.answers = this.answers.filter((ans) => {
              return ans.count > 0 || ans.isSelected;
            });
          },
        },
        text_topic: {
          name: "text_topic",
          text: () => {
            return `Algunos OA seleccionados requieren de un texto. <br> Así que ¡Vamos a crearlo automáticamente! <br> Dime <strong>¿De qué se va a tratar?<strong/>`;
          },
          type: "options",
          single_choice: true,
          answersLoaded: true,
          answers: [
            {
              name: "Ciencia Ficción",
              value: 1,
              isSelected: false,
              nextQuestionIndex: "text_topic_details",
            },
            {
              name: "Romance",
              value: 2,
              isSelected: false,
              nextQuestionIndex: "text_topic_details",
            },
            {
              name: "Viaje",
              value: 3,
              isSelected: false,
              nextQuestionIndex: "text_topic_details",
            },
          ],
          nextQuestionIndex: "text_topic_details",
          routine() {
            console.log("routine");
            // this.currentQuestionIndex = this.nextQuestionIndex;
          },
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                resolve(this.answers);
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
        },
        text_topic_details: {
          name: "text_topic_details",
          text: "Genial! puedes darme mas detalles sobre el texto",
          type: "text-area",
          answersLoaded: true,
          answers: [],
          optionalCheckbox: true,
          optionalCheckboxText: "Activar configuración personalizada <br> <span style='color: #757575; font-weight: normal;'>Podrás seleccionar: narrador, personajes, estilos, etc.</span>",
          nextQuestionIndex(context) {
            const optionalCheckbox = context["text_topic_details"].optionalCheckbox;
            return optionalCheckbox
              ? "plot_options"
              : "validate_text";
          },
          routine() {
            console.log("routine");
            // this.currentQuestionIndex = this.nextQuestionIndex;
          },
        },
        plot_options: {
          name: "plot_options",
          text: () => {
            return `¡Muy bien! Se me ocurren estas 3 tramas.`;
          },
          hint: "Escoge la que mas te guste",
          type: "options",
          answersLoaded: false,
          single_choice: true,
          answers: [
            {
              name: "",
              title: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "characters_options",
              isSelected: false,
            },
            {
              name: "",
              title: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "characters_options",
              isSelected: false,
            },
            {
              name: "",
              title: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "characters_options",
              isSelected: false,
            },
          ],
          getAnswers(context) {
            if (!this.answersLoaded) {
              return new Promise((resolve, reject) => {
                // const objectContext = this;
                assistedMaterialService
                  .generatePlots(
                    2,
                    3,
                    context["text_topic"]["value"],
                    context["text_topic_details"]["textInput"]
                  )
                  .then((response) => {

                    response.forEach((item, index) => {
                      this.answers[index].name = item.name;
                      this.answers[index].title = item.title;
                    });
                    resolve(this.answers);
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(error);
                  });
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          nextQuestionIndex: "characters_options",
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        characters_options: {
          name: "characters_options",
          text: () => {
            return `¡Muy bien! Se me ocurren estos personajes.`;
          },
          hint: "Escoge el que mas te guste",
          type: "options",
          answersLoaded: false,
          single_choice: true,
          answers: [
            {
              name: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "final_settings",
              isSelected: false,
            },
            {
              name: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "final_settings",
              isSelected: false,
            },
            {
              name: "",
              class: "col-12 px-0 pb-1",
              nextQuestionIndex: "final_settings",
              isSelected: false,
            },
          ],
          getAnswers(context) {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                const objectContext = this;
                assistedMaterialService
                  .generateCharacters(3, context.plot_options.name, 3)
                  .then((response) => {
                    response
                      .filter((r) => r.name !== "")
                        .forEach((chars, index) => {
                          chars.forEach((item, i) => {
                            objectContext.answers[index].name += `${i+1}. ${item.name}: ${item.description} <br>`;
                          });
                        });
           
                    resolve(this.answers);
                  });
              }).catch((error) => {
                console.log(error);
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          nextQuestionIndex: "final_settings",
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        final_settings: {
          name: "final_settings",
          text: () => {
            return `Estamos casi listos. <br> Ahora, algunos ajustes finales ...`;
          },
          type: "selector-options",
          answersLoaded: false,
          answers: [
            {
              name: "ajuste 1",
              class: "col-8 pb-2 px-0",
              options: [
                "Narrado en primera persona",
                "Narrado en tercera persona",
                "Narrador omnisciente",
              ],
              placeholder: "Selecciona el tipo de narrador",
              selected: "Narrado en tercera persona",
            },
            {
              name: "ajuste 2",
              class: "col-8 pb-2 px-0",
              options: ["Atmósfera de misterio y suspenso", "Cómico", "Acción"],
              placeholder: "Selecciona el tipo de atmósfera",
              selected: "Cómico",
            },
            {
              name: "ajuste 3",
              class: "col-8 pb-2 px-0",
              options: ["Solo texto", "Que incluya audio e imágenes"],
              placeholder: "Selecciona el tipo de contenido",
              selected: "Solo texto",
            },
          ],
          getAnswers() {
            if (!this.answersLoaded) {
              return new Promise((resolve) => {
                resolve(this.answers);
              });
            } else {
              return Promise.resolve(this.answers);
            }
          },
          routine() {
            console.log("routine");
            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
          nextQuestionIndex: "validate_text",
        },
        validate_text: {
          name: "validate_text",
          text: () => {
            const contentType = this.$store.state.chatbot.conversation.final_settings ?
              this.$store.state.chatbot.conversation.final_settings.answers[2]
                .selected.name : this.questions.final_settings.answers[2].selected.name;
            return contentType === "Que incluya audio e imágenes"
              ? "¡Perfecto, en menos de 1 minutos tendremos tu historia lista!"
              : "¡Muy bien! Esta es la historia que creaste ¿Quieres hacer algún cambio?";
          },
          type: "custom-component",
          nextMessage: "¡Listo! Guardar texto",
          component: TextMessageComponent,
          answersLoaded: true,
          loadingType: "progress-bar",
          loadingMax: 90, // seconds,
          nextQuestionIndex: "material",
          routine: () => {
            const contentType =
              this.$store.state.chatbot.conversation.final_settings ? 
              this.$store.state.chatbot.conversation.final_settings.answers[2].selected.name :
              this.questions.final_settings.answers[2].selected.name;
            const type = contentType === "Que incluya audio e imágenes" ? 1 : 0;

            if (type == 0) {
              assistedMaterialService
                .saveText({
                  title:
                    this.$store.state.chatbot.conversation.validate_text
                      .createdText.title,
                  text: this.$store.state.chatbot.conversation.validate_text
                    .createdText.content,
                  school: this.$store.state.login.school,
                })
                .then((response) => {
                  this.$store.commit("chatbot/updateMessage", {
                    name: "validate_text",
                    key: "createdText",
                    value: response,
                  });
                  console.log("response");
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            // let selectedAnswer = this.answers.find((ans) => ans.isSelected);
            // this.currentQuestionIndex = selectedAnswer.nextQuestionIndex;
          },
        },
        indicadores: {
          name: "indicadores",
          text: "Ahora, selecciona la cantidad de actividades por indicador de evaluación.",
          hint: () => {
            const appleIcon = this.appleIcon;
            return `Cada indicador cuesta 100 <img src='${appleIcon}' width='22px' class='pl-1 mb-2'/></img>`;
          },
          type: "options-counter",
          answersLoaded: true,
          nextQuestionIndex(context) {
            return context["subject"]["name"] === "Lenguaje"
              ? "text_topic"
              : "material";
          },
          answers: [],
          getAnswers(context) {
            return new Promise((resolve) => {
              return chatBotService.getPrice({ code: "IND" }).then((prices) => {
                context.oa.answers.forEach((ans) => {
                  let indicators = ans.subtags
                    .filter((s) => s.summary != null)
                    .map((i, index) => {
                      return {
                        name: `Ind. ${index + 1}: ${i.summary}`,
                        count: 0,
                        description: i.name,
                        price: prices[0].price,
                        id: i.id,
                      };
                    });
                  this.answers.push({
                    name_section: ans.name,
                    has_warning: true,
                    warning_text:
                      "Debes tener al menos dos actividades seleccionadas!",
                    key: "oaInds",
                    required: 2,
                    options: indicators,
                    class: "col-12",
                    oaInds: 0,
                    description: ans.description,
                  });
                });
                resolve(this.answers);
              });
            });
          },
          routine() {
            // let selectedAnswer = this.currentQuestion.answers.find(
            //   (ans) => ans.isSelected
            // );

            // iterar en answers y por cada answer iterar en options y eliminar los que tengan count 0 y también eliminar las answer con options vacíos

            this.answers.map((ans) => {
              ans.options = ans.options.filter((opt) => opt.count > 0);
              return ans.options.length > 0;
            });
          },
        },
        material: {
          name: "material",
          text: "¡Listo! Aquí está tu material. Puedes editarlo como quieras. <br> Cuando termines, puedes aplicarlo a tus estudiantes",
          type: "custom-component",
          answersLoaded: true,
          answers: [],
          component: CreateMaterial,
          nextMessage: "¡Listo! Guardar y aplicar",
          nextQuestionIndex: "material_title",
          routine: () => {},
          getAnswers() {
            return new Promise((resolve) => {
              resolve();
            });
          },
        },
        material_title: {
          name: "material_title",
          text: "¡Listo! Se ha creado tu material. Define un título para tu material y duración de la evaluación",
          type: "multiple-text",
          answersLoaded: true,
          showAnswers: true,
          answered: true,
          answers: [
            {
              name: "title",
              label: "Título",
              type: "text",
              value: "",
              class: "col-8 px-0",
            },
            {
              name: "duration",
              label: "Duración (minutos)",
              type: "number",
              value: 60,
              class: "col-8 px-0",
            },
          ],
          nextQuestionIndex: "application",
          routine: () => {
            const title =
              this.$store.state.chatbot.conversation.material_title.answers[0]
                .value;
            const grade =
              this.$store.state.chatbot.conversation.grade.answer.original_name;
            const subject =
              this.$store.state.chatbot.conversation.subject.answer.name;
            const newTitle = title
              ? title
              : `Aplicacion de ${subject} ${grade}`;
            const duration = parseInt(
              this.$store.state.chatbot.conversation.material_title.answers[1]
                .value
            );
            let createdMaterial =
              this.$store.state.chatbot.conversation.material.createdMaterial;
            createdMaterial.name = newTitle;
            createdMaterial.duration = duration;
            createdMaterial.grade =
              this.$store.state.chatbot.conversation.grade.answer.code;
            createdMaterial.subject =
              this.$store.state.chatbot.conversation.subject.answer.code;
            createdMaterial.ia_generated = true;
            customMaterialService
              .createMaterial(createdMaterial)
              .then((response) => {
                this.$store.commit("chatbot/updateMessage", {
                  name: "material",
                  key: "createdMaterial",
                  value: response,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          },
          getAnswers() {
            return new Promise((resolve) => {
              resolve(this.answers);
            });
          },
        },
        application: {
          name: "application",
          text: () => {
            const copyIcon = this.copyIcon;
            const reviewIcon = this.reviewIcon;
            const backIcon = this.backIcon;

            return `<strong>Creamos para ti tres estudiantes de prueba.<br> Ahora puedes:</strong><br>
              <ol>
                <li> <img src='${copyIcon}' width='22px' class='pl-1 mb-2'/></img> Copiar el link para realizar una evaluación </li>
                <li> <img src='${reviewIcon}' width='22px' class='pl-1 mb-2'/></img> Revisar una evaluación terminada. </li>
                <li> <img src='${backIcon}' width='22px' class='pl-1 mb-2'/></img> Devolverla a su estado original. </li> 
                <li> Chequear el porcentaje de logro. </li>
                <li> Ver el nivel de logro de cada OA. </li>
                <li> Aplicar un nuevo material ya creado. </li>
                <li> Revisar otras aplicaciones que hayas hecho en tu curso </li>
                <li> Añadir nuevos estudiantes a tu curso </li>
              </ol>`;
          },
          type: "custom-component",
          component: ApplicationComponent,
          answersLoaded: true,
          answers: [],
          nextQuestionIndex: "ask_premium",
          routine() {
            console.log("routine");
          },
          getAnswers() {
            return new Promise((resolve) => {
              resolve();
            });
          },
        },
        ask_premium: {
          name: "ask_premium",
          text: `Adicionalmente, puedes crear otro material o ¡Usar uno de nuestra Vitrina de Materiales Premium! <img src=${require("@/assets/img/icono-estrella.svg")} width='10px' class='pb-1'/></img>`,
          type: "options",
          single_choice: true,
          answersLoaded: true,
          answersFlow: true,
          starIcon: this.starIcon,
          answers: [
            {
              name: "Crear otro material",
              isSelected: false,
              class: "col-5 pr-1 pl-0 pb-1",
              nextQuestionIndex: "reset",
            },
            {
              name: `Vitrina de Materiales Premium <img src=${require("@/assets/img/icono-estrella.svg")} width='10px' class='pb-1'/></img>`,
              isSelected: false,
              class: "col-5 pr-1 pl-0 pb-1",
              nextQuestionIndex: "premium_view",
            },
          ],
          routine() {
            this.nextQuestionIndex = this.answers.find(
              (ans) => ans.isSelected
            ).nextQuestionIndex;
          },
          getAnswers() {
            return new Promise((resolve) => {
              resolve(this.answers);
            });
          },
        },
        premium_view: {
          name: "premium_view",
          text: "¡Mira estos materiales premium! <br> Puedes aplicarlos a tus estudiantes o personalizarlos a tu gusto.",
          type: "custom-component",
          component: PremiumViewComponent,
          answersLoaded: true,
          answers: [],
          nextQuestionIndex: "reset",
          routine() {
            console.log("routine");
          },
          getAnswers() {
            return new Promise((resolve) => {
              resolve();
            });
          },
        },
      },
    };
  },
  mounted() {
    let question = this.questions[this.currentQuestionIndex];
    this.messages.push(question);
    this.context[question.name] = {};
    this.$store.commit("chatbot/clear");
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    school() {
      return this.$store.state.login.school;
    },
    year() {
      return this.$store.state.login.year;
    },
  },
  methods: {
    handleCardClick(answer, msgidx, ansidx, max) {
      if (this.messages[msgidx].name === this.currentQuestion.name) {
        this.selectCard(msgidx, ansidx, answer, max);
        this.selectAnswer();
      }
    },
    handleIncrease(opt, answer) {
      if (opt.price) {
        if (opt.count === 0) {
          if (this.cost + opt.price <= this.$store.state.login.points) {
            this.cost += opt.price;
            opt.count++;
            answer.oaInds++;
          } else {
            this.$toasted.error(
              "No cuentas con aportes suficientes para realizar esta acción!"
            );
          }
        } else {
          if (opt.count < 2) {
            opt.count++;
            answer.oaInds++;
          }
        }
      } else {
        opt.count++;
        answer.oaInds++;
      }
    },
    handleDecrease(opt, answer) {
      if (opt.count > 0) {
        if (opt.price && opt.count === 1) {
          this.cost -= opt.price;
          opt.count--;
          answer.oaInds--;
        } else {
          opt.count--;
          answer.oaInds--;
        }
      }
    },
    continueChat() {
      // hacer switch para disstintos tipos de pregunta
      switch (this.currentQuestion.type) {
        case "options":
          if (this.currentQuestion.single_choice) {
            this.context[this.currentQuestion.name] =
              this.currentQuestion.answers.find((ans) => ans.isSelected);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answer",
              value: this.context[this.currentQuestion.name],
            });
          } else {
            this.context[this.currentQuestion.name] =
              this.currentQuestion.answers.filter((ans) => ans.isSelected);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answers",
              value: this.context[this.currentQuestion.name],
            });
          }
          break;
        case "options-counter":
          this.context[this.currentQuestion.name] =
            this.currentQuestion.answers;
          this.$store.commit("chatbot/updateMessage", {
            name: this.currentQuestion.name,
            key: "answers",
            value: this.context[this.currentQuestion.name],
          });
          break;

        case "cards":
          if (this.currentQuestion.single_choice) {
            this.context[this.currentQuestion.name] =
              this.currentQuestion.answers.find((ans) => ans.isSelected);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answer",
              value: this.context[this.currentQuestion.name],
            });
          } else {
            this.context[this.currentQuestion.name] =
              this.currentQuestion.answers.filter((ans) => ans.isSelected);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answers",
              value: this.context[this.currentQuestion.name],
            });
          }
          break;
        case "hybrid-options":
          // si optionalCheckbox es true, entonces type es option-counter si no, es multiple choice como cards
          if (this.currentQuestion.optionalCheckbox) {
            this.context[this.currentQuestion.name].answers =
              this.currentQuestion.answers.filter((ans) => ans.isSelected);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answers",
              value: this.context[this.currentQuestion.name].answers,
            });
          } else {
            this.context[this.currentQuestion.name].answers =
              this.currentQuestion.answers.filter((ans) => ans.count > 0);
            this.$store.commit("chatbot/updateMessage", {
              name: this.currentQuestion.name,
              key: "answers",
              value: this.context[this.currentQuestion.name].answers,
            });
          }
          break;
        case "text-area":
          this.context[this.currentQuestion.name].textInput =
            this.currentQuestion.textInput;
          this.$store.commit("chatbot/updateMessage", {
            name: this.currentQuestion.name,
            key: "textInput",
            value: this.context[this.currentQuestion.name].textInput,
          });
          break;
        case "selector-options":
          this.context[this.currentQuestion.name] =
            this.currentQuestion.answers;
          this.$store.commit("chatbot/updateMessage", {
            name: this.currentQuestion.name,
            key: "answers",
            value: this.context[this.currentQuestion.name],
          });
          break;
        case "material":
          this.context[this.currentQuestion.name] =
            this.currentQuestion.answers;
          this.$store.commit("chatbot/updateMessage", {
            name: this.currentQuestion.name,
            key: "answers",
            value: this.context[this.currentQuestion.name],
          });
          break;
        case "multiple-text":
          this.context[this.currentQuestion.name] =
            this.currentQuestion.answers;
          this.$store.commit("chatbot/updateMessage", {
            name: this.currentQuestion.name,
            key: "answers",
            value: this.context[this.currentQuestion.name],
          });
          break;
        default:
          break;
      }

      this.messages.slice(-1)[0].answered = true;
      if (!this.currentQuestion.isFinal) {
        this.currentQuestion.routine();
        if (!this.currentQuestion.single_choice) {
          if (typeof this.currentQuestion.nextQuestionIndex === "function") {
            this.currentQuestionIndex = this.currentQuestion.nextQuestionIndex(
              this.context
            );
          } else {
            this.currentQuestionIndex = this.currentQuestion.nextQuestionIndex;
          }
        } else {
          this.currentQuestionIndex =
            this.context[this.currentQuestion.name] &&
            this.context[this.currentQuestion.name].nextQuestionIndex
              ? this.context[this.currentQuestion.name].nextQuestionIndex
              : this.currentQuestion.nextQuestionIndex;
        }
        if (this.currentQuestionIndex == "reset") {
          this.resetChat();
        } else {
          this.loadQuestion(this.currentQuestionIndex);
        }
      }
    },
    selectCard(msgidx, answeridx, answer, max) {
      if (this.currentQuestion.single_choice) {
        this.messages[msgidx].answers.forEach((ans) => {
          if (ans.price && ans.isSelected) {
            this.cost -= ans.price;
          }
          ans.isSelected = false;
        });
      }
      if (answer.price && !answer.isSelected) {
        if (this.cost + answer.price <= this.$store.state.login.points) {
          if (
            !max ||
            (max &&
              this.messages[msgidx].answers.filter((ans) => ans.isSelected)
                .length < max)
          ) {
            this.cost += answer.price;
            answer.isSelected = true;
          } else {
            this.$toasted.error(
              "Ya has seleccionado el máximo de " + max + " elementos"
            );
          }
        } else {
          this.$toasted.error(
            "No cuentas con aportes suficientes para realizar esta acción!"
          );
        }
      } else {
        if (answer.price && answer.isSelected) {
          this.cost -= answer.price;
        }
        if (
          answer.isSelected ||
          !max ||
          (max &&
            this.messages[msgidx].answers.filter((ans) => ans.isSelected)
              .length < max)
        ) {
          this.messages[msgidx].answers[answeridx].isSelected =
            !this.messages[msgidx].answers[answeridx].isSelected;
        } else {
          this.$toasted.error(
            "Ya has seleccionado el máximo de " + max + " elementos"
          );
        }
      }
    },
    resetValues() {
      this.selectedCard = null;
    },

    setText(sourceObj, keyObj, targetObj, keyTarget) {
      return new Promise((resolve) => {
        // resolve content
        let content =
          typeof sourceObj[keyObj] === "function"
            ? sourceObj[keyObj]()
            : sourceObj[keyObj];

        // type effect
        let i = 0;
        // targetObj[keyTarget] = "";
        let timer = setInterval(() => {
          if (i < content.length && content.charAt(i)) {
            targetObj[keyTarget] += content.charAt(i);

            i++;

            this.$forceUpdate();
          } else {
            clearInterval(timer);
            resolve();
          }
        }, 1);
      });
    },
    loadQuestion(questionIndex) {
      try {
        let question = this.questions[questionIndex];
        this.context[question.name] = {};
        this.messages.push(question);
      } catch (error) {
        console.log(error);
      }
    },

    selectAnswer() {
      if (this.currentQuestion.single_choice) {
        this.continueChat();
      }
    },
    scrollToBottom() {
      let container = document.querySelector(".global-container");
      // container.scrollIntoView();
      window.scrollTo(0, container.scrollHeight);
    },

    scrollToMsg(name) {
      const msgRef = this.$refs[`msgRef-${name}`];
      const parentMsg = msgRef[0].$el.parentNode;

      parentMsg.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      // const scrollOffset = parentMsg.offsetTop;

      // window.scrollTo(0, scrollOffset);
    },
    resetChat() {
      // this.messages = [];
      // this.answers = {};
      // this.currentQuestionIndex = "type";
      // this.loadQuestion(this.currentQuestionIndex);
      this.$emit("toggle-reload");
    },
    handleCheckbox(msgidx, value) {
      this.messages[msgidx].optionalCheckbox = value;
      this.context[this.currentQuestion.name].optionalCheckbox = value;
    },
    setTextInput(msgidx, value) {
      this.messages[msgidx].textInput = value;
      this.context[this.currentQuestion.name].textInput = value;
    },
    handleSelectorChange(msgidx, idx, value) {
      this.messages[msgidx].answers[idx].selected = value;
      this.context[this.currentQuestion.name].answers =
        this.messages[msgidx].answers;
    },
  },
  watch: {
    cost(val) {
      this.$emit("set-cost", val);
    },
  },
};
</script>
<style>
.global-container {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

.image-container {
  background-image: url("../../assets/img/botpie/mancha.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
}

.chat-box {
  margin: 10px;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  align-items: space-between;
  justify-content: space-between;
}

/* RESET BUTTON */
/* CONTINUE BUTTON */
.reset-button {
  border-radius: 5px;
  border: 2pt solid rgb(0, 0, 0) !important;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
  cursor: pointer;
}
</style>
